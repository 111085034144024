export const CREATE_CHANNEL_CONTRACT_DIALOG_SHOW = 'CREATE_CHANNEL_CONTRACT_DIALOG_SHOW';
export const CREATE_CHANNEL_CONTRACT_DIALOG_HIDE = 'CREATE_CHANNEL_CONTRACT_DIALOG_HIDE';

export const FETCH_CHANNEL_CONTRACT_AVAILABILITY_SUCCESS = 'FETCH_CHANNEL_CONTRACT_AVAILABILITY_SUCCESS';
export const FETCH_CHANNEL_CONTRACT_AVAILABILITY_IN_PROGRESS = 'FETCH_CHANNEL_CONTRACT_AVAILABILITY_IN_PROGRESS';
export const FETCH_CHANNEL_CONTRACT_AVAILABILITY_ERROR = 'FETCH_CHANNEL_CONTRACT_AVAILABILITY_ERROR';

export const CHANNEL_CONTRACT_USER_NAME_SET = 'CHANNEL_CONTRACT_USER_NAME_SET';
export const CHANNEL_CONTRACT_USER_NAME_STATUS_SET = 'CHANNEL_CONTRACT_USER_NAME_STATUS_SET';

export const FETCH_CHANNEL_CONTRACT_SUCCESS = 'FETCH_CHANNEL_CONTRACT_SUCCESS';
export const FETCH_CHANNEL_CONTRACT_IN_PROGRESS = 'FETCH_CHANNEL_CONTRACT_IN_PROGRESS';
export const FETCH_CHANNEL_CONTRACT_ERROR = 'FETCH_CHANNEL_CONTRACT_ERROR';

export const FETCH_CHANNEL_CONTRACT_INFO_SUCCESS = 'FETCH_CHANNEL_CONTRACT_INFO_SUCCESS';
export const FETCH_CHANNEL_CONTRACT_INFO_ERROR = 'FETCH_CHANNEL_CONTRACT_INFO_ERROR';
export const FETCH_CHANNEL_CONTRACT_INFO_IN_PROGRESS = 'FETCH_CHANNEL_CONTRACT_INFO_IN_PROGRESS';

export const CHANNEL_CONTRACT_CHANGE_DIALOG_SHOW = 'CHANNEL_CONTRACT_CHANGE_DIALOG_SHOW';
export const CHANNEL_CONTRACT_CHANGE_DIALOG_HIDE = 'CHANNEL_CONTRACT_CHANGE_DIALOG_HIDE';

export const CHANNEL_CONTRACT_SETTINGS_TAB_SET = 'CHANNEL_CONTRACT_SETTINGS_TAB_SET';

export const SETTINGS_CHANNEL_CONTRACT_NAME_SET = 'SETTINGS_CHANNEL_CONTRACT_NAME_SET';
export const SETTINGS_CHANNEL_CONTRACT_DESCRIPTION_SET = 'SETTINGS_CHANNEL_CONTRACT_DESCRIPTION_SET';
export const SETTINGS_CHANNEL_CONTRACT_ADDRESS_SET = 'SETTINGS_CHANNEL_CONTRACT_ADDRESS_SET';
export const SETTINGS_CHANNEL_CONTRACT_ALLOW_PUBLISHERS_SET = 'SETTINGS_CHANNEL_CONTRACT_ALLOW_PUBLISHERS_SET';

export const SETTINGS_CHANNEL_CONTRACT_IMAGE_SET = 'SETTINGS_CHANNEL_IMAGE_SET';
export const CHANNEL_CONTRACT_USERNAME_SETTINGS_SET = 'CHANNEL_CONTRACT_USERNAME_SETTINGS_SET';

export const CHANNEL_CONTRACT_SAVE_BUTTON_ENABLE_SET = 'CHANNEL_CONTRACT_SAVE_BUTTON_ENABLE_SET';
